import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Container = styled.nav`
	width: 100%;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: 768px) {
	}

	@media (min-width: 1200px) {
	}
`

const Grid = styled.hgroup`
	width: 100%;
	/* margin: 0; */
	padding: 24px 0;

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 24px;

	& > * {
		grid-row: 1;
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 1200px) {
	}
`

const HeadingOne = styled.h1`
	grid-column: 1;
	font-weight: normal;
	font-size: 14px;
	letter-spacing: 0.25px;

	white-space: nowrap;
	border-bottom: 2px solid var(--primary-colour);

	& > * {
		/* color: var(--primary-text-colour); */
		text-decoration: none;
		transition: all 300ms;
		&:hover {
			color: var(--primary-colour);
		}
	}

	@media (min-width: 768px) {
	}
`

const RightAlignedGroup = styled.hgroup`
	grid-column: 1 / span 10;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	& > *:nth-child(n + 1) {
		margin-left: 12px;
	}

	@media (min-width: 375px) {
		grid-column: 4 / span 9;
	}

	@media (min-width: 768px) {
		& > *:nth-child(n + 1) {
			margin-left: 24px;
		}
	}
`

const HeadingTwo = styled.h2`
	margin-bottom: 0;
	font-size: 13.5px;
	letter-spacing: 0.25px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;

	transform: translateY(-1px);

	& > a svg {
		opacity: 0;
		transition: all 300ms ease-in-out;
		width: 0;
		transform: scaleX(0);
	}

	&:hover > a svg,
	&:active > a svg,
	&:focus > a svg {
		width: auto;
		opacity: 1;
		transform: scaleX(1);

		& > * {
			stroke: var(--primary-colour);
			stroke-width: 1;
		}
	}

	& > a {
		/* color: var(--primary-text-colour); */
		text-decoration: none;
		transition: all 300ms;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		&:hover {
			color: var(--primary-colour);
		}
	}

	@media (min-width: 768px) {
	}
`

const Header = () => (
	<Container>
		<Grid>
			<HeadingOne>
				<Link
					to="/"
					// activeStyle={{
					// 	color: '#007EFF'
					// }}
				>
					Aaron Vince
				</Link>
			</HeadingOne>
			<RightAlignedGroup>
				<HeadingTwo>
					<Link
						to="/"
						activeStyle={{
							color: '#007EFF'
						}}
					>
						Work
					</Link>
				</HeadingTwo>
				<HeadingTwo>
					<Link
						to="/about"
						activeStyle={{
							color: '#007EFF'
						}}
					>
						About
					</Link>
				</HeadingTwo>
				<HeadingTwo>
					<a href="mailto:aaronvince@me.com">
						Contact
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 32 24"
							width="22"
							height="16"
							style={{
								paddingLeft: '6px'
							}}
						>
							<rect
								x="3"
								y="4"
								fill="none"
								stroke="#fff"
								strokeWidth="0.5"
								strokeMiterlimit="10"
								width="26"
								height="16"
							/>
							<polygon
								fill="none"
								stroke="#fff"
								strokeWidth="0.5"
								strokeMiterlimit="10"
								points="29,7 16,15 3,7 3,4 29,4 "
							/>
							<polygon
								fill="none"
								stroke="#fff"
								strokeWidth="0.5"
								strokeMiterlimit="10"
								points="3,19 11,12 16,15 21,12 29,19 29,20 3,20"
							/>
						</svg>
					</a>
				</HeadingTwo>
			</RightAlignedGroup>
		</Grid>
	</Container>
)

Header.propTypes = {
	siteTitle: PropTypes.string
}

Header.defaultProps = {
	siteTitle: ''
}

export default Header
