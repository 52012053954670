import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const InformationSectionBlockContainer = styled.section`
	/* width: 100%; */
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-auto-rows: min-content;
	grid-gap: 24px;

	& > div:first-child,
	& > div:last-child {
		grid-column: 1 / span 12;
	}

	& > div:first-child {
		grid-row: 1;
	}

	& > div:last-child {
		grid-row: 2;
	}

	@media (min-width: 768px) {
		grid-gap: 24px;

		& > div:first-child,
		& > div:last-child {
			grid-row: 1;
		}

		& > div:first-child {
			grid-column: 1 / span 6;
		}

		& > div:last-child {
			grid-column: 7 / span 6;
		}
	}

	@media (min-width: 1200px) {
	}
`

const InformationQuartersContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-row: repeat(2, 1fr);
	grid-gap: 24px;
	min-height: 40vw;

	& > *:nth-child(1),
	& > *:nth-child(2) {
		grid-row: 1;
	}

	& > *:nth-child(3),
	& > *:nth-child(4) {
		grid-row: 2;
	}

	& > *:nth-child(1),
	& > *:nth-child(3) {
		grid-column: 1;
	}

	& > *:nth-child(2),
	& > *:nth-child(4) {
		grid-column: 2;
	}

	@media (min-width: 768px) {
		grid-gap: 24px;
	}

	@media (min-width: 1200px) {
		min-height: 330px;
	}
`

const ParagraphContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	grid-gap: 12px;

	& > * {
		grid-column: 1;
	}

	@media (min-width: 768px) {
		grid-gap: 24px;
	}

	@media (min-width: 1200px) {
	}
`

const Heading = styled.h3`
	font-size: 11pt;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding-top: 6px;
	color: var(--primary-colour);

	border-top: 2px solid var(--primary-colour);

	@media (min-width: 768px) {
		/* font-size: 64px; */
	}
`

const Paragraph = styled.p`
	font-size: 14px;
	line-height: 1.5;

	@media (min-width: 768px) {
		font-size: 16px;
		line-height: 1.4;
	}
`

const LinkOutIcon = styled.svg`
	transform: translateY(3px);
	width: 16px;
	height: 16px;
	margin-left: 10px;

	& > g > polyline,
	& > g > line {
		fill: none;
		stroke: var(--primary-colour);
		stroke-miterlimit: 10;
	}
`

const InformationSectionBlock = props => (
	<InformationSectionBlockContainer>
		<InformationQuartersContainer>
			{props.data.infoSection.map((quarter, index) => (
				<ParagraphContainer key={index}>
					<Heading>{quarter.heading}</Heading>
					<Paragraph>
						{typeof quarter.text === 'string'
							? quarter.text
							: quarter.text.map((text, index) =>
								text.link !== null ? (
									index === 0 ? (
										<a
											key={index}
											href={text.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{text.name},
										</a>
									) : index === quarter.text.length - 1 ? (
										<a
											key={index}
											href={text.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{text.name}
										</a>
									) : (
										<a
											key={index}
											href={text.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{text.name},
										</a>
									)
								) : (
									<span key={index}>{`${text.name} `}</span>
								)
							  )}
					</Paragraph>
				</ParagraphContainer>
			))}
		</InformationQuartersContainer>
		<ParagraphContainer>
			<Heading>Project Summary</Heading>
			{props.data.projectSummary.map((paragraph, index) => (
				<Paragraph key={index}>{paragraph}</Paragraph>
			))}
			{typeof props.data.projectLink.label === 'string' &&
			props.data.projectLink.label !== 'Example' ? (
					<Paragraph>
					Check it out here →{' '}
						<a
							href={props.data.projectLink.link}
							name="link-box"
							target="_blank"
							rel="noopener noreferrer"
						>
							{props.data.projectLink.label}
							<LinkOutIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
								<g>
									<polyline points="15.5,8 15.5,15.5 0.5,15.5 0.5,0.5 8,0.5 	" />
								</g>
								<g>
									<polyline points="10.5,0.5 15.5,0.5 15.5,5.5 	" />
									<line x1="8.5" y1="7.5" x2="15.5" y2="0.5" />
								</g>
							</LinkOutIcon>
						</a>
					</Paragraph>
				) : null}
		</ParagraphContainer>
	</InformationSectionBlockContainer>
)

InformationSectionBlock.propTypes = {
	data: PropTypes.shape({
		infoSection: PropTypes.array.isRequired,
		projectSummary: PropTypes.array.isRequired,
		projectLink: PropTypes.object
	})
}

InformationSectionBlock.defaultProps = {
	data: {
		infoSection: [
			{ heading: 'Role', text: 'Some Roles Here' },
			{ heading: 'Timeline', text: 'Month–Month YYYY (XX Weeks)' },
			{
				heading: 'Team',
				text: [
					{ name: 'name1', link: 'https://www.example.com' },
					{ name: 'name1', link: 'https://www.example.com' },
					{ name: 'name1', link: 'https://www.example.com' },
					{ name: '& Me', link: null }
				]
			},
			{
				heading: 'Tools',
				text: 'Some tools here.'
			}
		],
		projectSummary: ['Paragraph 1', 'Paragraph 2', 'Paragraph 3'],
		projectLink: {
			label: 'Example',
			link: 'https://www.example.com/'
		}
	}
}

export default InformationSectionBlock
