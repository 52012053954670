import React from 'react'

const ScrollUpButton = () => (
	// 	// From --> https://github.com/dirtyredz/react-scroll-up-button#tiny-up-button
	<svg
		style={{
			width: '32px',
			height: '32px',
			userSelect: 'none'
		}}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 32 32"
	>
		<polyline
			style={{
				fill: 'none',
				stroke: 'var(--primary-colour)',
				strokeMiterlimit: '10'
			}}
			points="4,20 16,12 28,20 "
		/>
	</svg>
)

export default ScrollUpButton
