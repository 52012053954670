import React from 'react'
import PropTypes from 'prop-types'
import ScrollUpButton from 'react-scroll-up-button'
import ScrollToTopSVG from './scrollToTopButton'
import { Helmet } from 'react-helmet'
import Header from './header'
import styled from 'styled-components'

import styles from '../styles/global.module.css'

const WebsiteContainer = styled.main`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;

	width: 92vw;
	padding: 0 4vw;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
		width: 100%;
		max-width: 1200px;
		padding: 0;
		margin: auto;
	}
`

const ContentContainer = styled.section`
	width: 100%;
	min-height: 80vh;
	padding: 0;
	margin: 0;

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-auto-rows: min-content;
	grid-gap: 12px;

	& > * {
		grid-column: 1 / span 12;
	}

	@media (min-width: 768px) {
		grid-gap: 24px;
	}

	@media (min-width: 1200px) {
		max-width: 1200px;
	}
`

const Footer = styled.footer`
	font-size: smaller;
	letter-spacing: 0.2px;
	font-variant-numeric: tabular-nums;
	color: var(--primary-colour);
	text-align: center;
	padding-top: 24px;
	padding-bottom: 24px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Layout = ({ children }) => (
	<WebsiteContainer>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Aaron Vince’s Portfolio</title>
			<link rel="canonical" href="https://www.aaronvince.com/" />
		</Helmet>
		<Header />
		<ContentContainer>
			{children}

			<ScrollUpButton
				ContainerClassName={styles.ScrollUpButtonContainer}
				TransitionClassName={styles.ScrollUpButtonToggled}
				EasingType={'easeInOutCirc'}
			>
				<ScrollToTopSVG />
			</ScrollUpButton>
		</ContentContainer>
		<Footer>Aaron Vince © {new Date().getFullYear()}</Footer>
	</WebsiteContainer>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout
