import React from 'react'
import styled from 'styled-components'

const SeparatorContainer = styled.svg`
	width: 100%;
	height: auto;
	margin: 12px 0;

	@media (min-width: 768px) {
		margin: 36px 0 24px 0;
	}
`

const GradientStop = styled.stop`
	offset: 0.1;
	stop-color: hsla(0, 0%, 0%, 0.16);

	@media (prefers-color-scheme: light) {
		stop-color: hsla(0, 0%, 0%, 0.04);
	}
`

const SVGSeparator = () => (
	<SeparatorContainer
		id="separator"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 960 24"
	>
		<title>Content Separator</title>
		<defs>
			<linearGradient id="maskGradient" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0" stopColor="hsla(0, 0%, 100%, 0)" />
				<stop offset="0.3" stopColor="hsla(0, 0%, 100%, 1)" />
				<stop offset="0.7" stopColor="hsla(0, 0%, 100%, 1)" />
				<stop offset="1" stopColor="hsla(0, 0%, 100%, 0)" />
			</linearGradient>

			<linearGradient id="rectGradient" x1="0%" y1="0%" x2="0%" y2="100%">
				<stop offset="0" stopColor="hsla(0, 0%, 0%, 0)" />
				<GradientStop />
				<stop offset="1" stopColor="hsla(0, 0%, 0%, 0)" />
			</linearGradient>
		</defs>
		<mask id="myMask" width="960" height="24">
			<rect fill="url(#maskGradient)" width="960" height="24" />
		</mask>
		<rect
			fill="url(#rectGradient)"
			width="960"
			height="24"
			mask="url(#myMask)"
		/>
	</SeparatorContainer>
)

export default SVGSeparator
